<template>
  <div class="hetong">
    <van-nav-bar :title="'สัญญาเงินกู้'" @click-left="$router.go(-1)">
      <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="container">
      <div style="text-align: center;">
        <img :src="require('./img/hetong1.png')" width="100" height="100" alt="" srcset="">
      </div>
      <div class="des">
        <div class="content-item">รหัส: {{id}}</div>
        <div class="content-item">สัญญาเงินกู้ การลงนามทั้งสองฝ่าย{{createTime}}</div>
        <div class="content-item">ลงชื่อ : {{name}}</div>
        <div class="content-item">ผู้ปล่อยกู้:UDOMSIN TURAKIT PICO COMPANY LIMITED</div>
        <div class="content-item">ทั้งสองฝ่ายได้เจรจาอย่างเท่าเทียมกัน</div>
        <div class="content-item">ผู้กู้กับผู้ปล่อยสินเชื่อได้ตกลงตามเงื่อนไขทั้งหมดและมีความน่าเชื่อถือทั้งสองฝ่าย</div>
        <div class="content-item"> ระยะเวลาจำกัดในการกู้ยืม</div>
      </div>
      <div class="content">
        <p class="content-item">จำนวนเงินกู้ :【 {{quota}} 】</p>
        <p class="content-item">1.ระยะเวลาการยืมกู้ : จาก {{createTime}} ถึง {{getZoneTime}} , รวม [ {{months}}   เดือน
ซึ่งต่อไปในสัญญานี้จะเยกว่า "ลูกค้า" หรือ "ผู้ให้ยืม" หรือ "ผู้ยืม" แล้วแต่กรณี
ฝ่ายหนึ่ง UDOMSIN TURAKIT PICO COMPANY LIMITED โดยที่บริษัทเป็นผู้ที่ใด้รับอนุญาตจากรัฐมนตรีว่าการกระทรวงการคลัง
ให้ประกอบธุรกิจหลักทรัพย์ประเภท กิจการการยืมและให้ยืมหลักทรัพย์ มีความประสงค์ที่จะเข้าทำธุรกรรมการให่ยืมหลักทรัพย์
ดูสัญญาแต่ละฝ่ายในฐานะผู้ให้ยืมหรือผู้ยืม เพื่อวัตถุประสงค์ ตังต่อไปนี้</p>
        <p class="content-item">2.ในกรณีผู้ยืมกู้ผ่านออนไสน์โดยไม่สมารถใช้หลักทรัพย์ค้ำประกัน (ผู้ให้กู้มีความเสี่ยงในการปล่อยกู้)
ผู้กู่จึงจำเป็นต้องมิการค้ำประกันวงเงินกู้เพื่อตรวจสอบสภาพคล่องในการชำระหนี้ขั้นต่ำ ผู้กู้จะต้องได้รับการตรวจสอบสภาพคล่องทางการเงิน
เงื่อนไขแยกต่างหากจากข้อกำหนด หรือ เงื่อนไขที่สมบูรณ์ โดยใช้ข้อกำหนดหรือเงื่อนไขที่ สมบูรณ์มิผลบังคับใช้ได้ต่อไป</p>
        <p class="content-item">3.ในกรณีที่คู่สัญญาได้รับการอนุมัติสินเชื่อแล้วคู่สัญญา หากมิความประสงค์ยกเลิกสัญญาทางแพลตฟอร์มออนไสน์
คู่สัญญามีความยินยอมที่ประสงค์ชำระค่ายกเลิกสัญญา 30% ของวงเงินที่ใด้รับการอนุมัติ</p>
        <p class="content-item">4.หากผู้ไม่ปฏิบัติตามสัญญานี้ ผู้ยินยอมรับผิดชอบและชดใช้ค่าเสิยหายทั้งหมดคือผู้กู้
รวมทั้งค่าใช้จ่ายทั้งบ่วงในการเตือน หวงถาม เรียกร้องให้ชำระหนี้ตลอดจนค่าธรรมนิยม คำใช้จ่ายในการดำเนินตติ และค่าทนายในการดำเนินคดีบังคับให้ชำระหนี้</p>
        <p class="content-item">คืนให้แก่ผู้ให้กู้เพื่อเป็นหลักฐานคู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความในสัญญาจึงได้ลงลายมือเซ็นและประทับตราบริษัทไว้เพื่อเป็นหลักฐานสำคัญ</p>
        <p class="content-item">(ผู้กู้ยินยอมรับหรือทำตามเงื่อนไขของทางบริษัท)</p>
      </div>
      <div class="footer">
        <p class="content-item">ผู้กู้ {{name}}</p>
        <p class="content-item">ลายมือชื่อ:</p>
        <div style="text-align: center;">
          <img :src="img" width="300px" height="150px" alt="" srcset="">
        </div>
        <p class="content-item">ผู้ปล่อยกู้ :UDOMSIN TURAKIT PICO COMPANY LIMITED</p>
        <p class="content-item">วันที่ลงนาม : {{createTime}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import './css/my.css'
import { getAutograph, getLoanAgreementInfo } from '@/api/my'
import dayjs from 'dayjs'
export default {
  name: 'hetong',
  data () {
    return {
      // 签名
      img: '',
      // 手机号
      phone: '',
      // 贷款金额
      quota: '',
      // 利率
      rate: '',
      // 姓名
      name: '',
      // 贷款期限
      months: '',
      // 身份证
      idCardNo: '',
      // 当前时间
      getZoneTimenum: '',
      autograph: '',
      createTime: '',
      id: 0
    }
  },
  created () {
    // this.getSign()
    this.init_getLoanAgreementInfo()
  },
  computed: {
    // 获取创建时间加上日期
    getZoneTime () {
      // console.log(dayjs('2020-09-04').add(this.months, 'month').format('YYYY-MM-DD'))
      return dayjs(this.createTime).add(this.months, 'month').format('YYYY-MM-DD')
    }
  },
  methods: {
    // 获取签名
    async getSign () {
      const { data } = await getAutograph()
      this.img = `${data.data}`
    },
    // 获取签名
    async init_getLoanAgreementInfo () {
      const { data } = await getLoanAgreementInfo()
      this.id = data.data.id
      this.img = `${data.data.autograph}`
      this.idCardNo = data.data.idCardNo
      this.months = data.data.months
      this.name = data.data.name
      this.phone = data.data.phone
      this.quota = data.data.quota
      this.rate = data.data.rate
      this.createTime = dayjs(data.data.createTime).format('YYYY-MM-DD')
      this.getZoneTimenum = this.getLocalTime(8)
    },
    // 获取时间
    getLocalTime (i) {
      // 参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
      if (typeof i !== 'number') return
      var d = new Date()
      // 得到1970年一月一日到现在的秒数
      var len = d.getTime()
      // 本地时间与GMT时间的时间偏移差
      var offset = d.getTimezoneOffset() * 60000
      // 得到现在的格林尼治时间
      var utcTime = len + offset
      var time = new Date(utcTime + 3600000 * i)
      // return time.getFullYear() + '-' + time.getMonth() + 1 +'-'+dd+' '+hh+':'+mm+':'+ss
      return `${time.getFullYear()}-${time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1}-${time.getDate() < 10 ? '0' + time.getDate() : time.getDate()}`
    }
  }
}
</script>

<style scoped>
.hetong {
  font-size: 16px;
  background-color: #fff;
}
.container {
  padding: 20px 30px;
}
.title {
  font-size: 35px;
  text-align: center;
}
.des {
  font-size: 18px;
  padding: 10px 0;
}
.content {
  background: url('./img/hetong2.png') no-repeat center;
  background-size: 375px 300px;
}
.content-item {
  text-align: center;
  padding: 5px 0;
}
.footer {
  padding-bottom: 30px;
}
</style>
